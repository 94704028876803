import React, { Component } from 'react'
import axios from "axios";
import { observer, inject } from "mobx-react"
import NumberFormat from 'react-number-format';
import Moment from 'react-moment';
import { BrowserView, MobileView } from "react-device-detect";

import { Bar } from 'react-chartjs-2';

import Map from "./Map"
class Dashboard extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loadingDisplay: true,
            languageTr: true,
            lastUpdatedDate: "",
            graphtype: "0",
            predictionInfoMenu: false,
            countries: [],
            countriesgeo: { "countries": "none" },
            countriestimeline: [],
            globalData: [],
            mapHeight: "calc(100vh- 100px)",
            graphMenu: "none",
            cumulativeNavActive: "graphLinkActive",
            histogramBarActive: "graphLink",
            cumulativeGraphDisplay: "activeGraph",
            histogramGraphDisplay: "passiveGraph",
            firstPrediction: "3.4.2021",
            secondPrediction: "13.4.2021",   
            histogramData: {},
            mobileInfoMenu: "none",
            barOptions: {
                legend: {
                    labels: {
                        fontColor: 'rgb(35, 8, 87)'
                    },
                    display: false
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true,
                            fontColor: 'rgb(35, 8, 87)',
                            fontStyle: "bold",
                            callback: function (value, index, values) {
                                if (parseInt(value) >= 1000) {
                                    return (value / 1000).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " K";
                                } else {
                                    return value;
                                }
                            },
                            autoSkipPadding: 20

                        },
                        stacked: true
                    }],
                    xAxes: [{
                        ticks: {
                            autoSkip: true,
                            fontColor: 'rgb(35, 8, 87)',
                            fontStyle: "bold",
                            maxRotation: 0,
                            minRotation: 0,
                            padding: 15,
                            labelOffset: 15,
                            autoSkipPadding: 50
                        },
                        gridLines: {
                            display: false
                        },
                        stacked: true
                    }]
                }
            },
        }
    }

    componentDidMount = async () => {

        this.setState({
            loadingDisplay: true
        })

        let globalResult = null;

        let countryResult = null;
        let countryGeoResult = null;

        this.props.store.setMapCenter([0, 5, 1.2])

        await axios.get(`https://api.mapisso.com/covid19/v1/global`)
            .then((res) => {
                globalResult = res.data
                console.log("global result: ", globalResult)
            }).catch((error) => {
                globalResult = null
            });

        if (globalResult != null) {

            let globalPerdiction = [{
                date: globalResult.result[0].predictions[0].date,
                value: globalResult.result[0].predictions[0].value
            }, {
                date: globalResult.result[0].predictions[1].date,
                value: globalResult.result[0].predictions[1].value
            }]

            let globalData = {
                country: "Global",
                countryTr: "Dünya Geneli",
                cases: globalResult.result[0].cases,
                todayCases: globalResult.result[0].newCases,
                deaths: globalResult.result[0].deaths,
                todayDeaths: globalResult.result[0].newDeath,
                recovered: globalResult.result[0].recovered,
                critical: globalResult.result[0].critical,
                casesPerOneMillion: Number(globalResult.result[0].casesPerOneMillion.toFixed(0)),
                deathsPerOneMillion: Number(globalResult.result[0].deathsPerOneMillion.toFixed(0)),
                centerLat: 0,
                centerLng: 5,
                totalTests: globalResult.result[0].tests,
                testsPerOneMillion: Number((7594000000 / globalResult.result[0].tests).toFixed(0)),
                predictions: globalPerdiction
            }

            this.props.store.setSelectedCountry(globalData)
            this.props.store.setGlobal(globalData)
            this.state.lastUpdatedDate = globalResult.result[0].updated
        }

        await fetch("https://pomber.github.io/covid19/timeseries.json")
            .then(response => response.json())
            .then(data => {
                var worldStats = { cases: 0, recovered: 0, deaths: 0 };
                var countryArr = Object.keys(data).map(i => i);
                countryArr.forEach((country) => {
                    let countryData = data[country];
                    // pick last object for today data
                    countryData = countryData[countryData.length - 1];
                    worldStats.cases += countryData.confirmed;
                    worldStats.recovered += countryData.recovered;
                    worldStats.deaths += countryData.deaths;
                });
                // world data
                var worldChart = [];
                countryArr.forEach((country) => {
                    let countryData = data[country];
                    countryData.forEach((dailyData, index) => {
                        if (worldChart[index] === undefined) {
                            var worldStats = { date: dailyData.date, cases: dailyData.confirmed, recovered: dailyData.recovered, deaths: dailyData.deaths };
                            worldChart.push(worldStats);
                        } else {
                            worldChart[index].cases += dailyData.confirmed;
                            worldChart[index].recovered += dailyData.recovered;
                            worldChart[index].deaths += dailyData.deaths;
                        }
                    });

                });
                this.setState({
                    countryTimelineData: worldChart,
                    globalData: worldChart
                });

            });

        this.createHistogramData(this.state.graphtype)

        await axios.get(`https://api.mapisso.com/covid19/v1/countries`)
            .then((res) => {
                countryResult = res.data
            }).catch((error) => {
                countryResult = null
            });
            console.log("countryResult: ", countryResult)

        if (countryResult != null) {
            this.setState({
                countries: countryResult.result
            })
        }

        await axios.get(`https://api.mapisso.com/covid19/v1/countries/geojson`)
            .then((res) => {
                countryGeoResult = res.data
            }).catch((error) => {
                countryGeoResult = null
            });

        if (countryGeoResult != null) {
            this.setState({
                countriesgeo: countryGeoResult.result
            })
        }

        this.setState({
            loadingDisplay: false
        })

    }



    /**
     * Update global covid19 data at map, infobox and reports
     */
    updateGlobalData = () => {

        let predictionsArray = [{
            date: this.props.store.global[0].predictions[0].date,
            value: this.props.store.global[0].predictions[0].value
        }, {
            date: this.props.store.global[0].predictions[1].date,
            value: this.props.store.global[0].predictions[1].value
        }]

        let globalData = {
            country: this.props.store.global[0].country,
            countryTr: this.props.store.global[0].countryTr,
            cases: this.props.store.global[0].cases,
            todayCases: this.props.store.global[0].todayCases,
            deaths: this.props.store.global[0].deaths,
            todayDeaths: this.props.store.global[0].todayDeaths,
            recovered: this.props.store.global[0].recovered,
            critical: this.props.store.global[0].critical,
            casesPerOneMillion: this.props.store.global[0].casesPerOneMillion,
            deathsPerOneMillion: this.props.store.global[0].deathsPerOneMillion,
            centerLat: this.props.store.global[0].centerLat,
            centerLng: this.props.store.global[0].centerLng,
            totalTests: this.props.store.global[0].totalTests,
            testsPerOneMillion: this.props.store.global[0].testsPerOneMillion,
            predictions: predictionsArray
        }

        this.props.store.setMapCenter([21.637201177212773, 21.281334741723867, 1])
        this.props.store.setSelectedCountry(globalData)

        let globalDataTimelinee = this.state.globalData

        this.setState({
            countryTimelineData: globalDataTimelinee
        })

        this.createHistogramData(this.state.graphtype, globalDataTimelinee)

    }

    /**
     * Update user selected country covid data at map, infobox and reports
     */
    updateCountryData = async (item) => {

        this.props.store.setMapCenter([item.geom.coordinates[0], item.geom.coordinates[1], 5])

        let countryData = {
            country: item.country,
            countryTr: item.countryTr,
            cases: item.cases,
            todayCases: item.todayCases,
            deaths: item.deaths,
            todayDeaths: item.todayDeaths,
            recovered: item.recovered,
            critical: item.critical,
            casesPerOneMillion: Number(item.casesPerOneMillion.toFixed(0)),
            deathsPerOneMillion: Number(item.deathsPerOneMillion.toFixed(0)),
            centerLat: item.geom.coordinates[0],
            centerLng:item.geom.coordinates[1],
            totalTests: item.tests,
            testsPerOneMillion: item.testsPerOneMillion,
            predictions: item.predictions
        }

        this.props.store.setSelectedCountry(countryData);

        let countryTimeline = null;
        let countryName = this.props.store.selectedCountry[0].country

        await axios.get(`https://api.mapisso.com/covid19/v1/timeline/${countryName}`)
            .then((res) => {
                countryTimeline = res.data.result[0].timeline
            }).catch((error) => {
                countryTimeline = null
            });

        if (countryTimeline != null) {
            this.setState({
                countryTimelineData: countryTimeline,
                mobileInfoMenu: "block"
            })
            this.createHistogramData(this.state.graphtype)
        }

    }

    /**
    * This method uses timelinedata for creating histogram graphdata
    * @param {timelineData} timelineData 
    */
    createHistogramData(graphType, timeline) {
        //histogramGraphButtonText: "Show Daily Histogram Graph",
        //histogramGraphButtonTextTr: "Günlük Histogram Grafiği Göster",
        //histogramGraphButtonClicked: false,

        let timelineData = []
        if (timeline !== undefined) {
            timelineData = timeline
        } else {
            timelineData = this.state.countryTimelineData
        }

        let labels = []
        let cases = []
        let deaths = []
        let recovered = []

        let tempCaseData = 0;
        let tempRecoveredData = 0;
        let tempDeathData = 0;

        for (var item of timelineData) {
            labels.push(item.date)
            cases.push(item.cases - tempCaseData)
            deaths.push(item.deaths - tempDeathData)
            recovered.push(item.recovered - tempRecoveredData)
            tempCaseData = item.cases
            tempRecoveredData = item.recovered
            tempDeathData = item.deaths
        }

        let tempLabel = []
        let tempCases = []
        let tempDeaths = []
        let tempRecovered = []

        for (let i = 0; i < timelineData.length; i++) {
            if (timelineData[i].deaths > 1) {
                tempLabel.push(labels[i])
                tempCases.push(cases[i])
                tempDeaths.push(deaths[i])
                tempRecovered.push(recovered[i])
            }
        }

        labels = tempLabel
        cases = tempCases
        deaths = tempDeaths
        recovered = tempRecovered

        let labelValue = ""
        let data = []

        if (graphType === "0") {
            labelValue = "Cases"
            data = cases
        } else if (graphType === "1") {
            labelValue = "Tedavi Edilen Vakalar"
            data = deaths
        } else if (graphType === "2") {
            labelValue = "Ölüm Vakaları"
            data = recovered
        }

        var barChartData = {
            labels: labels,
            datasets: [{
                label: labelValue,
                backgroundColor: "rgb(140, 80, 251)",
                data: data
            }]
        };

        this.setState({
            histogramData: barChartData,
        })

    }

    onLanguageChangeClicked(countryTrSelected) {

        if (countryTrSelected) {
            this.setState({
                languageTr: true
            })
        } else {
            this.setState({
                languageTr: false
            })
        }

    }

    openPredictionInfoMenu = () => {
        this.setState({
            predictionInfoMenu: true
        })
    }

    closePredictionInfoMenu = () => {
        this.setState({
            predictionInfoMenu: false
        })
    }

    onClickMobileCountryList = () => {
        this.setState({
            mobileCountryListIsActive: "9999",
            mobileMapIsActive: "0",
            mobileGraphIsActive: "0",
            countrymobilebutton: "navButtonActive",
            mapmobilebutton: "navButton"
        })
    }

    onChangeGraphType = (event) => {
        this.createHistogramData(event.target.value)
    }

    onMobileInfoCloseMenu = () => {
        this.setState({
            mobileInfoMenu: "none"
        })
    }

    render() {

        let selectedCountry = this.props.store.selectedCountry[0];
        let global = this.props.store.global[0];

        console.log("selectedCountry: ------", selectedCountry )

        return (
            <div className="container-fluid">
                <BrowserView>
                    <div className="row" style={{ height: "calc(100vh - 100px)", marginTop: "100px" }}>
                        <div className="col-md-2" style={{ padding: "10px", height: "calc(100vh - 100px)", width: "300px" }}>
                            <div className="row" style={{ margin: "0" }}>
                                <div className="col-md-12 global-ozet">
                                    <p>Global</p>

                                    <p style={{ fontWeight: "bold" }}>
                                        {this.state.languageTr &&
                                            "Toplam Vaka"
                                        }
                                        {!this.state.languageTr &&
                                            "Total Cases"
                                        }
                                    </p>

                                    <p className="global-ozet-total-case">
                                        <NumberFormat value={global.cases} displayType={'text'} thousandSeparator={true} />
                                    </p>

                                    <p className="global-ozet-small-header">
                                        {this.state.languageTr &&
                                            "Toplam Ölüm"
                                        }
                                        {!this.state.languageTr &&
                                            "Total Deaths"
                                        }
                                        <NumberFormat value={global.deaths} displayType={'text'} thousandSeparator={true} style={{ float: "right" }} />
                                    </p>

                                    <p className="global-ozet-small-header">
                                        {this.state.languageTr &&
                                            "Toplam İyileşen"
                                        }
                                        {!this.state.languageTr &&
                                            "Total Recovered"
                                        }
                                        <NumberFormat value={global.recovered} displayType={'text'} thousandSeparator={true} style={{ float: "right" }} />
                                    </p>
                                </div>
                            </div>
                            <div className="overflow-auto countryListBox">
                                <div className="row country-list" onClick={() => this.updateGlobalData()}>
                                    <div className="col-3">
                                        <p style={{ fontSize: "12px", marginTop: "2px" }}>
                                            <NumberFormat value={(global.todayCases / (global.cases - global.todayCases)) * 100} prefix="%" displayType={'text'} decimalScale="2" /></p>
                                        <i className="fas fa-chart-line" style={{ color: "#ea4040" }}></i>
                                    </div>
                                    <div className="col-9" style={{ paddingLeft: "0" }}>
                                        <span>
                                            {this.state.languageTr &&
                                                "Dünya Geneli"
                                            }
                                            {!this.state.languageTr &&
                                                "Global"
                                            }
                                        </span>

                                        {this.state.languageTr &&
                                            <p style={{ fontSize: "12px" }}><span style={{ color: "#009fff" }}>
                                                <NumberFormat value={global.cases} displayType={'text'} thousandSeparator={true} /> Vaka</span>
                                            </p>
                                        }
                                        {!this.state.languageTr &&
                                            <p style={{ fontSize: "12px" }}><span style={{ color: "#009fff" }}>
                                                <NumberFormat value={global.cases} displayType={'text'} thousandSeparator={true} style={{ marginLeft: "5px" }} /> Cases</span>
                                            </p>
                                        }

                                    </div>

                                </div>

                                {this.state.countries.map((item, idx) => (
                                    <div key={idx} className="row country-list" onClick={() => this.updateCountryData(item)}>
                                        <div className="col-3" >
                                            <p style={{ fontSize: "12px", marginTop: "2px" }}>
                                                <NumberFormat value={(item.todayCases / (item.cases - item.todayCases)) * 100} prefix="%" displayType={'text'} decimalScale="2" /></p>
                                            <i className="fas fa-chart-line" style={{ color: "#ea4040" }}></i>
                                        </div>
                                        <div className="col-9" style={{ paddingLeft: "0" }}>
                                            <p style={{ fontSize: "14px" }}>
                                                {this.state.languageTr &&
                                                    item.countryTr
                                                }
                                                {!this.state.languageTr &&
                                                    item.country
                                                }
                                            </p>

                                            {this.state.languageTr &&
                                                <p style={{ fontSize: "12px" }}><span style={{ color: "#009fff", marginLeft: "5px" }}>
                                                    <NumberFormat value={item.cases} displayType={'text'} thousandSeparator={true} /> Vaka</span>
                                                    <span style={{ color: "#ea4040", marginLeft: "5px" }}>
                                                        <NumberFormat value={item.deaths} displayType={'text'} thousandSeparator={true} /> Ölüm</span></p>
                                            }
                                            {!this.state.languageTr &&
                                                <p style={{ fontSize: "12px" }}><span style={{ color: "#009fff" }}>
                                                    <NumberFormat value={item.cases} displayType={'text'} thousandSeparator={true} /> Cases</span>
                                                    <span style={{ color: "#ea4040", marginLeft: "5px" }}>
                                                        <NumberFormat value={item.deaths} displayType={'text'} thousandSeparator={true} /> Deaths</span></p>
                                            }
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="col-7" style={{ padding: "0" }}>
                            <Map height={this.state.mapHeight} countries={this.state.countriesgeo} centerLat={this.state.centerLat} centerLng={this.state.centerLng}></Map>
                        </div>
                        <div className="col-md-3">

                            <div className="col-md-12 global-ozet" style={{ marginTop: "10px", height: "350px" }}>
                                <p style={{ fontSize: "35px" }}>
                                    {this.state.languageTr &&
                                        selectedCountry.countryTr
                                    }
                                    {!this.state.languageTr &&
                                        selectedCountry.country
                                    }
                                </p>

                                <p style={{ fontWeight: "bold" }}>
                                    {this.state.languageTr &&
                                        "Toplam Vaka"
                                    }
                                    {!this.state.languageTr &&
                                        "Total Cases"
                                    }
                                </p>

                                <p className="global-ozet-total-case">
                                    <NumberFormat value={selectedCountry.cases} displayType={'text'} thousandSeparator={true} />
                                    <span className="infoBox-todaynumber">+<NumberFormat value={selectedCountry.todayCases} displayType={'text'} thousandSeparator={true} /></span>
                                </p>

                                <p style={{ fontWeight: "bold" }}>
                                    {this.state.languageTr &&
                                        "Toplam Ölüm"
                                    }
                                    {!this.state.languageTr &&
                                        "Total Deaths"
                                    }
                                </p>

                                <p className="global-ozet-total-case" style={{ color: "rgb(234, 64, 64)" }}>
                                    <NumberFormat value={selectedCountry.deaths} displayType={'text'} thousandSeparator={true} />
                                    <span className="infoBox-todaynumber">+<NumberFormat value={selectedCountry.todayDeaths} displayType={'text'} thousandSeparator={true} /></span>
                                </p>

                                <p className="global-ozet-small-header">
                                    {this.state.languageTr &&
                                        "Toplam İyileşen"
                                    }
                                    {!this.state.languageTr &&
                                        "Total Recovered"
                                    }
                                    <NumberFormat value={selectedCountry.recovered} displayType={'text'} thousandSeparator={true} style={{ float: "right" }} />
                                </p>

                                <p className="global-ozet-small-header">
                                    {this.state.languageTr &&
                                        "Kritik"
                                    }
                                    {!this.state.languageTr &&
                                        "Critical"
                                    }
                                    <NumberFormat value={selectedCountry.critical} displayType={'text'} thousandSeparator={true} style={{ float: "right" }} />
                                </p>
                                <p className="global-ozet-small-header">
                                    {this.state.languageTr &&
                                        "Yeni Vakalar"
                                    }
                                    {!this.state.languageTr &&
                                        "New Cases"
                                    }
                                    <span style={{ float: "right" }}>+ <NumberFormat value={selectedCountry.todayCases} displayType={'text'} thousandSeparator={true} /></span>
                                </p>
                                <p className="global-ozet-small-header">
                                    {this.state.languageTr &&
                                        "Yeni Ölümler"
                                    }
                                    {!this.state.languageTr &&
                                        "New Deaths"
                                    }
                                    <span style={{ float: "right" }}>+ <NumberFormat value={selectedCountry.todayDeaths} displayType={'text'} thousandSeparator={true} style={{ float: "right" }} /> </span>
                                </p>
                            </div>

                            <div className="global-ozet" style={{ height: "110px", background: "#6400e5", color: "#fff" }}>
                                <p style={{ fontSize: "14px", fontWeight: "bold", color: "#fff" }}>
                                    {this.state.languageTr &&
                                        "Tahmini Vaka Sayısı"
                                    }
                                    {!this.state.languageTr &&
                                        "Covid-19 Cases Predictions"
                                    }
                                    <span style={{ color: "#fff" }}>
                                        <i className="fas fa-info-circle close-button" onClick={() => this.openPredictionInfoMenu()}
                                            style={{ float: "right", color: "#fff", marginTop: "5px", marginLeft: "10px" }}></i>
                                    </span>
                                </p>
                                <p className="global-ozet-small-header" style={{ borderBottom: "1px solid #e0e0e0", paddingBottom: "8px", color: "#fff" }}>
                                    {this.state.firstPrediction}
                                    <NumberFormat value={this.props.store.selectedCountry[0].predictions[0].value} displayType={'text'} thousandSeparator={true} style={{ float: "right" }} />
                                </p>
                                <p className="global-ozet-small-header" style={{ color: "#fff" }}>
                                    {this.state.secondPrediction}
                                    <NumberFormat value={this.props.store.selectedCountry[0].predictions[1].value} displayType={'text'} thousandSeparator={true} style={{ float: "right" }} />
                                </p>
                            </div>

                            <div className="prediction-Info" style={{ display: (this.state.predictionInfoMenu) ? "block" : "none" }}>
                                <i className="fas fa-times close-button" onClick={() => this.closePredictionInfoMenu()}
                                    style={{ float: "right", color: "gray", marginTop: "5px", marginLeft: "10px" }}></i>
                                <p>
                                    {this.state.languageTr &&
                                        "Bu tahmin verisi, makine öğrenmesi teknikleriyle, pandemi başlangıç noktasından itibaren yayınlanan veriler kullanılarak üretilmiştir."
                                    }
                                    {!this.state.languageTr &&
                                        "This prediction data has been produced by machine learning techniques, using the data published from the pandemic starting point."
                                    }
                                </p>
                            </div>

                            <div className="overflow-auto graph-container">
                                <p style={{ fontWeight: "bold", fontSize: "14px" }}>
                                    {this.state.languageTr &&
                                        "Günlük Vaka Grafiği"
                                    }
                                    {!this.state.languageTr &&
                                        "Daily Case Graph"
                                    }
                                </p>
                                <select className="custom-select graph-selector"
                                    onChange={(event) => this.onChangeGraphType(event)}>
                                    <option value="0">
                                        {this.state.languageTr &&
                                            "Günlük Vakalar"
                                        }
                                        {!this.state.languageTr &&
                                            "Daily Cases"
                                        }
                                    </option>
                                    <option value="1">
                                        {this.state.languageTr &&
                                            "Ölüm Vakalar"
                                        }
                                        {!this.state.languageTr &&
                                            "Deaths Cases"
                                        }
                                    </option>
                                    <option value="2">
                                        {this.state.languageTr &&
                                            "Tedavi Edilen Vakalar"
                                        }
                                        {!this.state.languageTr &&
                                            "Recovered"
                                        }
                                    </option>
                                </select>
                                <div style={{ marginTop: "50px" }}>
                                    <Bar data={this.state.histogramData} height={120} width={200} options={this.state.barOptions} />
                                </div>
                            </div>

                        </div>
                        <div className="dataStatus-Container">
                            <span style={{ fontSize: "12px", color: "gray" }}>Data Status: <Moment fromNow>{this.state.lastUpdatedDate}</Moment> updated</span>
                            <i className="fas fa-info-circle" style={{ float: "right", color: "gray", marginTop: "5px", marginLeft: "10px" }}></i>

                            {this.state.languageTr &&
                                <span style={{ float: "right", fontSize: "12px", color: "#8b8b8b", marginTop: "3px", fontWeight: "bold" }}>Veri Kaynağı: <a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019" style={{ color: "#8b8b8b", fontWeight: "normal" }}>WHO </a>
                                           Harita Altlığı: <a href="https://www.mapbox.com/" style={{ color: "#8b8b8b", fontWeight: "normal" }}>Mapbox</a></span>
                            }
                            {!this.state.languageTr &&
                                <span style={{ float: "right", fontSize: "12px", color: "#8b8b8b", marginTop: "3px", fontWeight: "bold" }}>Data Source: <a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019" style={{ color: "#8b8b8b", fontWeight: "normal" }}>WHO </a>
                                           Map Data: <a href="https://www.mapbox.com/" style={{ color: "#8b8b8b", fontWeight: "normal" }}>Mapbox</a></span>
                            }

                        </div>
                    </div>

                    <div className="language">
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <p onClick={() => this.onLanguageChangeClicked(false)} className="activateEnglish">EN</p>
                                    </td>
                                    <td>
                                        <p onClick={() => this.onLanguageChangeClicked(true)} className="activateTurkish">TR</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="data-api">
                        <a href="https://documenter.getpostman.com/view/226774/TzsbJmWk"><i className="fas fa-code" style={{ fontWeight: "bold" }}></i> Covid19 Open Data API</a>
                    </div>
                    <img src="assets/images/loading.gif" className="loading-gif" style={{ display: (this.state.loadingDisplay) ? 'block' : 'none' }} alt="loading-gif"></img>
                    <div className="row mainLogoContainer">
                        <div className="col-1" style={{ minWidth: "139px" }}>
                            <p className="logo-font"> <i className="fas fa-virus"></i> Covid19
                                 {this.state.languageTr && <span style={{ fontSize: "14px", float: "right" }}> Durum Haritası</span>}
                                {!this.state.languageTr && <span style={{ fontSize: "14px", float: "right" }}> Status Map</span>}
                            </p>
                        </div>
                        <div className="col-2">
                            {this.state.languageTr &&
                                <p style={{ fontSize: "14px", marginLeft: "53px", width: "157px", marginTop: "11px" }}>İnsanlık için geliştirildi
                                 <a href="https://mapisso.com" style={{ fontSize: "20px", fontWeight: "bold", fontFamily: "Poppins-Bold", color: "#007bff" }}>
                                        <img src="assets/images/mapisso-logo.png" style={{ width: "139px", marginTop: "3px" }} alt="mapisso-logo"></img>
                                    </a>
                                </p>
                            }

                            {!this.state.languageTr &&
                                <p style={{ fontSize: "14px", marginLeft: "53px", width: "157px", marginTop: "11px" }}>Developed for humanity
                                <a href="https://mapisso.com" style={{ fontSize: "20px", fontWeight: "bold", fontFamily: "Poppins-Bold", color: "#007bff" }}>
                                        <img src="assets/images/mapisso-logo.png" style={{ width: "139px", marginTop: "3px" }} alt="mapisso-logo"></img>
                                    </a></p>
                            }

                        </div>
                        <div className="col-5">
                        </div>
                        <div className="col-4">
                        </div>
                    </div>
                </BrowserView>
                <MobileView>
                    <div className="row" style={{
                        height: "110px", background: "#fff", padding: "5px", paddingTop: "10px", paddingBottom: "10px",
                        borderBottom: "1px solid #ecebeb", position: "absolute", top: "0", margin: "0"}}>
                        <div className="col-6" style={{ borderRight: "1px solid #fff", padding: "16px", paddingTop: "0" }}>
                            <p className="logo-font" style={{ color: "rgb(100, 0, 229)", borderRight: "none", marginLeft: "-5px" }}> <i className="fas fa-virus"></i> Covid19
                                {this.state.languageTr && <span style={{ fontSize: "14px", float: "right" }}> Durum Haritası</span>}
                                {!this.state.languageTr && <span style={{ fontSize: "14px", float: "right" }}> Status Map</span>}
                            </p>
                        </div>
                        <div className="col-6" style={{ textAlign: "right" }}>
                            {this.state.languageTr &&
                                <p style={{ fontSize: "12px", marginTop: "11px", color: "rgb(100, 0, 229)" }}>
                                    İnsanlık için geliştirildi
                                    <a href="https://mapisso.com" style={{ fontSize: "20px", fontWeight: "bold", fontFamily: "Poppins-Bold" }}>
                                        <img src="assets/images/mapisso-logo.png" style={{ width: "139px", marginTop: "3px" }} alt="mapisso-logo"></img>
                                    </a>
                                </p>
                            }

                            {!this.state.languageTr &&
                                <p style={{ fontSize: "12px", marginTop: "11px", color: "rgb(100, 0, 229)" }}>Developed for humanity
                                <a href="https://mapisso.com" style={{ fontSize: "20px", fontWeight: "bold", fontFamily: "Poppins-Bold" }}>
                                        <img src="assets/images/mapisso-logo.png" style={{ width: "139px", marginTop: "3px" }} alt="mapisso-logo"></img>
                                    </a>
                                </p>
                            }

                            <div className="language-mobile">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <img src="assets/images/turkey.png" alt="türkçe" className="turkishButtonMobile" onClick={() => this.onLanguageChangeClicked(true)}></img>
                                            </td>
                                            <td>
                                                <img src="assets/images/england.png" alt="english" className="englishButtonMobile" onClick={() => this.onLanguageChangeClicked(false)}></img>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <img src="assets/images/loading.gif" className="loading-gif" style={{ display: (this.state.loadingDisplay) ? 'block' : 'none', 
                                    left: "-10px", top: "62px",  width: "35px"}} alt="loading-gif"></img>
                        </div>
                    </div>

                    <div className="col-md-12 global-ozet" style={{ marginTop: "130px", height: "183px" }}>
                        <p>Global</p>
                        <p style={{ fontWeight: "bold" }}>
                            {this.state.languageTr &&
                                "Toplam Vaka"
                            }
                            {!this.state.languageTr &&
                                "Total Cases"
                            }
                        </p>

                        <p className="global-ozet-total-case">
                            <NumberFormat value={global.cases} displayType={'text'} thousandSeparator={true} />
                        </p>

                        <p className="global-ozet-small-header">
                            {this.state.languageTr &&
                                "Toplam Ölüm"
                            }
                            {!this.state.languageTr &&
                                "Total Deaths"
                            }
                            <NumberFormat value={global.deaths} displayType={'text'} thousandSeparator={true} style={{ float: "right" }} />
                        </p>

                        <p className="global-ozet-small-header">
                            {this.state.languageTr &&
                                "Toplam İyileşen"
                            }
                            {!this.state.languageTr &&
                                "Total Recovered"
                            }
                            <NumberFormat value={global.recovered} displayType={'text'} thousandSeparator={true} style={{ float: "right" }} />
                        </p>
                    </div>
                    <div id="countrylistcontainter" className="row" style={{ position: "relative" }}>
                        <div className="overflow-auto countryListBox" style={{ width: "100%", background: "#fff", height: "calc(100vh - 375px)" }}>
                            <div className="row country-list" onClick={() => this.updateGlobalData()} style={{ padding: "3px" }}>
                                <div className="col-3">
                                    <p style={{ fontSize: "12px", marginTop: "2px", marginLeft: "3px" }}>
                                        <NumberFormat value={(global.todayCases / (global.cases - global.todayCases)) * 100} prefix="%" displayType={'text'} decimalScale="2" /></p>
                                    {(global.todayCases / (global.cases - global.todayCases)) * 100 >= 0.05 &&
                                        <i className="fas fa-chart-line" style={{ color: "#ea4040", marginLeft: "3px" }}></i>
                                    }
                                    {(global.todayCases / (global.cases - global.todayCases)) * 100 < 0.05 &&
                                        <i className="fas fa-chart-line" style={{ color: "#7af960", marginLeft: "3px" }}></i>
                                    }
                                </div>

                                {this.state.languageTr &&
                                    <div className="col-5" style={{ paddingLeft: "0" }}>
                                        <p style={{ fontSize: "14px", fontWeight: "bold" }}>Dünya Geneli</p>
                                        <p style={{ fontSize: "12px" }}><span style={{ color: "#009fff" }}>
                                            <NumberFormat value={global.cases} displayType={'text'} thousandSeparator={true} /> Vaka</span>
                                        </p>
                                        <p style={{ fontSize: "12px" }}><span style={{ color: "#ea4040" }}>
                                            <NumberFormat value={global.deaths} displayType={'text'} thousandSeparator={true} /> Ölüm</span>
                                        </p>
                                    </div>
                                }
                                {!this.state.languageTr &&
                                    <div className="col-5" style={{ paddingLeft: "0" }}>
                                        <p style={{ fontSize: "14px", fontWeight: "bold" }}>Global</p>
                                        <p style={{ fontSize: "12px" }}><span style={{ color: "#009fff" }}>
                                            <NumberFormat value={global.cases} displayType={'text'} thousandSeparator={true} /> Cases</span>
                                        </p>
                                        <p style={{ fontSize: "12px" }}><span style={{ color: "#ea4040" }}>
                                            <NumberFormat value={global.deaths} displayType={'text'} thousandSeparator={true} /> Deaths</span>
                                        </p>
                                    </div>
                                }

                                {this.state.languageTr &&
                                    <div className="col-4">
                                        <p style={{ textAlign: "left", fontSize: "11px", color: "#009fff" }}>+
                                    <NumberFormat value={global.todayCases} displayType={'text'} thousandSeparator={true} /> V </p>
                                        <p style={{ textAlign: "left", fontSize: "11px", color: "#ea4040" }}>+
                                    <NumberFormat value={global.todayDeaths} displayType={'text'} thousandSeparator={true} /> Ö </p>
                                    </div>
                                }
                                {!this.state.languageTr &&
                                    <div className="col-4">
                                        <p style={{ textAlign: "left", fontSize: "11px", color: "#009fff" }}>+
                                        <NumberFormat value={global.todayCases} displayType={'text'} thousandSeparator={true} /> C </p>
                                        <p style={{ textAlign: "left", fontSize: "11px", color: "#ea4040" }}>+
                                        <NumberFormat value={global.todayDeaths} displayType={'text'} thousandSeparator={true} /> D </p>
                                    </div>
                                }
                            </div>

                            {this.state.countries.map((item, idx) => (
                                <div key={idx} className="row country-list" onClick={() => this.updateCountryData(item)} style={{ padding: "3px" }}>
                                    <div className="col-3">
                                        <p style={{ fontSize: "12px", marginTop: "2px", marginLeft: "3px" }}> <NumberFormat value={(item.todayCases / (item.cases - item.todayCases)) * 100} prefix="%" displayType={'text'} decimalScale="2" /></p>
                                        {(item.todayCases / item.cases) * 100 >= 0.5 &&
                                            <i className="fas fa-chart-line" style={{ color: "#ea4040" }}></i>
                                        }
                                        {(item.todayCases / item.cases) * 100 < 0.5 &&
                                            <i className="fas fa-chart-line" style={{ color: "#7af960" }}></i>
                                        }
                                    </div>

                                    {this.state.languageTr &&
                                        <div className="col-5" style={{ paddingLeft: "0" }}>
                                            <p style={{ fontSize: "14px", fontWeight: "bold" }}>{item.countryTr}</p>
                                            <p style={{ fontSize: "12px", color: "#009fff" }}>
                                                <span >
                                                    <NumberFormat value={item.cases} displayType={'text'} thousandSeparator={true} />
                                                </span>  Vaka
                                            </p>
                                            <p style={{ fontSize: "12px", color: "#ea4040" }}><span>
                                                <NumberFormat value={item.deaths} displayType={'text'} thousandSeparator={true} />
                                            </span>  Ölüm
                                            </p>
                                        </div>
                                    }
                                    {!this.state.languageTr &&
                                        <div className="col-5" style={{ paddingLeft: "0" }}>
                                            <p style={{ fontSize: "14px", fontWeight: "bold" }}>{item.country}</p>
                                            <p style={{ fontSize: "12px", color: "#009fff" }}>
                                                <span>
                                                    <NumberFormat value={item.cases} displayType={'text'} thousandSeparator={true} />
                                                </span> Cases
                                            </p>
                                            <p style={{ fontSize: "12px", color: "#ea4040" }}>
                                                <span>
                                                    <NumberFormat value={item.deaths} displayType={'text'} thousandSeparator={true} />
                                                </span> Deaths
                                            </p>
                                        </div>
                                    }

                                    {this.state.languageTr &&
                                        <div className="col-4">
                                            <p style={{ textAlign: "left", fontSize: "10px", color: "#009fff" }}>
                                                + <NumberFormat value={item.todayCases} displayType={'text'} thousandSeparator={true} /> V </p>
                                            <p style={{ textAlign: "left", fontSize: "10px", color: "#ea4040" }}>
                                                + <NumberFormat value={item.todayDeaths} displayType={'text'} thousandSeparator={true} /> Ö </p>
                                        </div>
                                    }
                                    {!this.state.languageTr &&
                                        <div className="col-4">
                                            <p style={{ textAlign: "left", fontSize: "10px", color: "#009fff" }}>
                                                + <NumberFormat value={item.todayCases} displayType={'text'} thousandSeparator={true} /> C </p>
                                            <p style={{ textAlign: "left", fontSize: "10px", color: "#ea4040" }}>
                                                + <NumberFormat value={item.todayDeaths} displayType={'text'} thousandSeparator={true} /> D </p>
                                        </div>
                                    }
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="dataStatus-Container">
                        <span style={{ fontSize: "12px", color: "gray" }}>Data Status: <Moment fromNow>{this.state.lastUpdatedDate}</Moment> updated</span>
                        <i className="fas fa-info-circle" style={{ float: "right", color: "gray", marginTop: "5px", marginLeft: "10px" }}></i>

                        {this.state.languageTr &&
                            <span style={{ float: "left", fontSize: "12px", color: "#8b8b8b", marginTop: "3px", fontWeight: "bold" }}>Veri Kaynağı:
                                    <a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019" style={{ color: "#8b8b8b", fontWeight: "normal" }}>WHO </a>
                                    Harita Altlığı: <a href="https://www.mapbox.com/" style={{ color: "#8b8b8b", fontWeight: "normal" }}>Mapbox</a></span>
                        }
                        {!this.state.languageTr &&
                            <span style={{ float: "left", fontSize: "12px", color: "#8b8b8b", marginTop: "3px", fontWeight: "bold" }}>Data Source: <a href="https://www.who.int/emergencies/diseases/novel-coronavirus-2019" style={{ color: "#8b8b8b", fontWeight: "normal" }}>WHO </a>
                                           Map Data: <a href="https://www.mapbox.com/" style={{ color: "#8b8b8b", fontWeight: "normal" }}>Mapbox</a></span>
                        }
                    </div>
                    <div className="mobile-countryInfo" style={{display: this.state.mobileInfoMenu}}>
                        <p onClick={() => this.onMobileInfoCloseMenu()}><i class="fas fa-chevron-left"></i> 
                                {(this.state.languageTr) ? " Ana Sayfa": " Main Page"}</p>
                        <div className="col-md-12 global-ozet" style={{ marginTop: "10px", height: "350px" }}>
                            <p style={{ fontSize: "35px" }}>
                                {this.state.languageTr &&
                                    selectedCountry.countryTr
                                }
                                {!this.state.languageTr &&
                                    selectedCountry.country
                                }
                            </p>

                            <p style={{ fontWeight: "bold" }}>
                                {this.state.languageTr &&
                                    "Toplam Vaka"
                                }
                                {!this.state.languageTr &&
                                    "Total Cases"
                                }
                            </p>

                            <p className="global-ozet-total-case">
                                <NumberFormat value={selectedCountry.cases} displayType={'text'} thousandSeparator={true} />
                                <span className="infoBox-todaynumber">+<NumberFormat value={selectedCountry.todayCases} displayType={'text'} thousandSeparator={true} /></span>
                            </p>

                            <p style={{ fontWeight: "bold" }}>
                                {this.state.languageTr &&
                                    "Toplam Ölüm"
                                }
                                {!this.state.languageTr &&
                                    "Total Deaths"
                                }
                            </p>

                            <p className="global-ozet-total-case" style={{ color: "rgb(234, 64, 64)" }}>
                                <NumberFormat value={selectedCountry.deaths} displayType={'text'} thousandSeparator={true} />
                                <span className="infoBox-todaynumber">+<NumberFormat value={selectedCountry.todayDeaths} displayType={'text'} thousandSeparator={true} /></span>
                            </p>

                            <p className="global-ozet-small-header">

                                {this.state.languageTr &&
                                    "Toplam İyileşen"
                                }
                                {!this.state.languageTr &&
                                    "Total Recovered"
                                }
                                <NumberFormat value={selectedCountry.recovered} displayType={'text'} thousandSeparator={true} style={{ float: "right" }} />
                            </p>

                            <p className="global-ozet-small-header">

                                {this.state.languageTr &&
                                    "Kritik"
                                }
                                {!this.state.languageTr &&
                                    "Critical"
                                }
                                <NumberFormat value={selectedCountry.critical} displayType={'text'} thousandSeparator={true} style={{ float: "right" }} />
                            </p>
                            <p className="global-ozet-small-header">

                                {this.state.languageTr &&
                                    "Yeni Vakalar"
                                }
                                {!this.state.languageTr &&
                                    "New Cases"
                                }
                                <span style={{ float: "right" }}>+ <NumberFormat value={selectedCountry.todayCases} displayType={'text'} thousandSeparator={true} /></span>
                            </p>
                            <p className="global-ozet-small-header">

                                {this.state.languageTr &&
                                    "Yeni Ölümler"
                                }
                                {!this.state.languageTr &&
                                    "New Deaths"
                                }
                                <span style={{ float: "right" }}>+ <NumberFormat value={selectedCountry.todayDeaths} displayType={'text'} thousandSeparator={true} style={{ float: "right" }} /> </span>
                            </p>
                        </div>

                        <div className="global-ozet" style={{ height: "110px", background: "#6400e5", color: "#fff" }}>
                            <p style={{ fontSize: "14px", fontWeight: "bold", color: "#fff" }}>
                                {this.state.languageTr &&
                                    "Tahmini Vaka Sayısı"
                                }
                                {!this.state.languageTr &&
                                    "Covid-19 Cases Predictions"
                                }
                                <span style={{ color: "#fff" }}>
                                    <i className="fas fa-info-circle close-button" onClick={() => this.openPredictionInfoMenu()}
                                        style={{ float: "right", color: "#fff", marginTop: "5px", marginLeft: "10px" }}></i>
                                </span>
                            </p>
                            <p className="global-ozet-small-header" style={{ borderBottom: "1px solid #e0e0e0", paddingBottom: "8px", color: "#fff" }}>
                                {this.state.firstPrediction}
                                <NumberFormat value={this.props.store.selectedCountry[0].predictions[0].value} displayType={'text'} thousandSeparator={true} style={{ float: "right" }} />
                            </p>
                            <p className="global-ozet-small-header" style={{ color: "#fff" }}>
                                {this.state.secondPrediction}
                                <NumberFormat value={this.props.store.selectedCountry[0].predictions[1].value} displayType={'text'} thousandSeparator={true} style={{ float: "right" }} />
                            </p>
                        </div>

                        <div className="prediction-Info" style={{ display: (this.state.predictionInfoMenu) ? "block" : "none" }}>
                            <i className="fas fa-times close-button" onClick={() => this.closePredictionInfoMenu()}
                                style={{ float: "right", color: "gray", marginTop: "5px", marginLeft: "10px" }}></i>
                            <p>
                                {this.state.languageTr &&
                                    "Bu tahmin verisi, makine öğrenmesi teknikleriyle, pandemi başlangıç noktasından itibaren yayınlanan veriler kullanılarak üretilmiştir."
                                }
                                {!this.state.languageTr &&
                                    "This prediction data has been produced by machine learning techniques, using the data published from the pandemic starting point."
                                }
                            </p>
                        </div>

                        <div className="graph-container">
                            <p style={{ fontWeight: "bold", fontSize: "14px" }}>
                                {this.state.languageTr &&
                                    "Günlük Vaka Grafiği"
                                }
                                {!this.state.languageTr &&
                                    "Daily Case Graph"
                                }
                            </p>
                            <select className="custom-select graph-selector"
                                onChange={(event) => this.onChangeGraphType(event)}>
                                <option value="0">
                                    {this.state.languageTr &&
                                        "Günlük Vakalar"
                                    }
                                    {!this.state.languageTr &&
                                        "Daily Cases"
                                    }
                                </option>
                                <option value="1">
                                    {this.state.languageTr &&
                                        "Ölüm Vakalar"
                                    }
                                    {!this.state.languageTr &&
                                        "Deaths Cases"
                                    }
                                </option>
                                <option value="2">
                                    {this.state.languageTr &&
                                        "Tedavi Edilen Vakalar"
                                    }
                                    {!this.state.languageTr &&
                                        "Recovered"
                                    }
                                </option>
                            </select>
                            <div style={{ marginTop: "50px", marginBottom: "50px" }}>
                                <Bar data={this.state.histogramData} height={120} width={200} options={this.state.barOptions} />
                            </div >
                            <div style={{ marginTop: "50px", marginBottom: "50px"}}>
                                <Map height={"150px"} countries={this.state.countriesgeo} centerLat={this.state.centerLat} centerLng={this.state.centerLng}></Map>
                            </div>
                        </div>
                    </div>

                </MobileView>
            </div >
        )
    }
}

export default inject("store")(observer(Dashboard));
