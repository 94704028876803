import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import { Provider } from "mobx-react";
import { onSnapshot } from 'mobx-state-tree'

import { Covid19Store } from './store/Covid19Store'

let initialState = {
  mapCenterLat: 21.637201177212773,
  mapCenterLng: 21.281334741723867,
  mapZoom: 1.4,
  selectedCountry: [{
      country: "",
      countryTr: "",
      cases: 0,
      todayCases: 0,
      deaths: 0,
      todayDeaths: 0,
      recovered: 0,
      critical: 0,
      casesPerOneMillion: 0,
      deathsPerOneMillion: 0,
      centerLat: 0,
      centerLng: 0,
      totalTests: 0,
      testsPerOneMillion: 0,
      predictions: [{
        date:"",
        value: 0
      },{
        date:"",
        value: 0
      }]
  }],
  global: [{
    country: "",
    countryTr: "",
    cases: 0,
    todayCases: 0,
    deaths: 0,
    todayDeaths: 0,
    recovered: 0,
    critical: 0,
    casesPerOneMillion: 0,
    deathsPerOneMillion: 0,
    centerLat: 0,
    centerLng: 0,
    totalTests: 0,
    testsPerOneMillion: 0,
    predictions: [{
      date:"",
      value: 0
    },{
      date:"",
      value: 0
    }]
}]
}

if (localStorage.getItem("mapisso.covid19.v2.0.2")) {
 // console.log("localstorage if")
 //localStorage.clear("mapisso.covid19")
  initialState = JSON.parse(localStorage.getItem("mapisso.covid19.v2.0.2"))
}

let covid19Store = Covid19Store.create(initialState)

onSnapshot(covid19Store, snapshot => {
  localStorage.setItem("mapisso.covid19.v2", JSON.stringify(snapshot))
})

function renderApp() {
  ReactDOM.render(
      <Provider store={covid19Store}>
          <App />
      </Provider>, document.getElementById('root'));
}

renderApp()


