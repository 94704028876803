import { types } from "mobx-state-tree"

import {Country} from "../model/Country"

export const Covid19Store = types
    .model("Covid19Store", {
        mapCenterLat: types.number,
        mapCenterLng: types.number,
        mapZoom: types.number,
        selectedCountry: types.optional(types.array(Country), []),
        global: types.optional(types.array(Country), [])
    }).actions(self => ({
        setMapCenter(coordinatesarray) {
            self.mapCenterLat = coordinatesarray[0]
            self.mapCenterLng = coordinatesarray[1]
            self.mapZoom = coordinatesarray[2]
        },
        setSelectedCountry: (function (newCountry) {
            console.log("selectedCountryMethod", newCountry)
            self.selectedCountry = []
            self.selectedCountry.push(newCountry)
            console.log("self.selectedCountry.: ", self.selectedCountry)
        }),
        setGlobal: (function (global) {
            self.global = []
            self.global.push(global)
        }),
        setCases: (function(newCases){
            self.cases = []
            self.cases.push(newCases)
        }),
        setRecovered: (function (newRecovered){
            self.recovered = []
            self.recovered.push(newRecovered)
        }),
        setDeaths: (function(newDeaths){
            self.deaths = []
            self.deaths.push(newDeaths)
        })

    }))
