import React, { Component } from 'react'
import { observer, inject } from "mobx-react"

import ReactMapboxGl, { MapContext, ZoomControl } from "react-mapbox-gl";
import { Country } from "../model/Country"

const Mapbox = ReactMapboxGl({
    accessToken:
        "pk.eyJ1IjoibXVoYW1tZXRlbXJleWlsZGlyaW0iLCJhIjoieFJRX3Y0dyJ9.1q-BXxW6oTiOONEWogfxmQ"
});

class Map extends Component {

    constructor(props) {
        super(props)
        this.state = {
            width: "100%"
        }
    }

    onMoveMap = (map) => {

        // console.log("map: ", map.getCenter())
        // console.log("zoom: ", map.getZoom())

    }

    onSelectedCountries = (data) => {

        console.log("---------------------------", data)

        this.props.store.setSelectedCountry(data)
        this.props.store.setMapCenter([data.centerLat, data.centerLng, 5])
    }

    render() {

        let mapCenterLat = this.props.store.mapCenterLat
        let mapCenterLng = this.props.store.mapCenterLng
        let mapZoom = this.props.store.mapZoom

        return (
            <div>
                <Mapbox style="mapbox://styles/muhammetemreyildirim/cki512qrd056519pk1lrdii1c" // eslint-disable-line
                    center={[mapCenterLat, mapCenterLng]}
                    zoom={[mapZoom]} pitch={[0]}
                    bearing={[0]}
                    containerStyle={{
                        height: "calc(100vh - 100px)",
                        width: this.state.width
                    }} >

                    <ZoomControl />
                    <MapContext.Consumer>
                        {(map) => {

                            // mapbox://styles/mapbox/satellite-v9 uydu görüntüsü altlığı
                            // mapbox://styles/mapbox/satellite-streets-v11 uydu ve streets altlığı
                            map.on('moveend', () => {
                                this.onMoveMap(map)
                            });

                            if (this.props.countries.countries !== "none") {

                                console.log("this.props.countries: ", this.props.countries)

                                if (!map.getSource("countries")) {

                                    let data = {
                                        'type': 'geojson',
                                        'data': this.props.countries
                                    }

                                    map.addSource("countries", data);

                                    map.addLayer({
                                        'id': 'population',
                                        'type': 'circle',
                                        'source': 'countries',
                                        //'source-layer': 'sf2010',
                                        'paint': {
                                            // make circles larger as the user zooms from z12 to z22
                                            'circle-radius': [
                                                'step',
                                                ['get', 'cases'],
                                                2,
                                                5000,
                                                5,
                                                15000,
                                                7,
                                                30000,
                                                9,
                                                70000,
                                                11,
                                                500000,
                                                13,
                                                1000000,
                                                25
                                            ],
                                            // color circles by ethnicity, using a match expression
                                            // https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-match
                                            'circle-color': '#6400e5',
                                            'circle-opacity': 0.5

                                        }
                                    });

                                    map.on('click', 'population', (e) => {

                                        let properties = e.features[0].properties;

                                        let predictionsArray = JSON.parse(properties.predictions)

                                        let data = Country.create({
                                            country: properties.country,
                                            countryTr: properties.countrytr,
                                            recovered: properties.recovered,
                                            cases: properties.cases,
                                            todayCases: properties.active,
                                            deaths: properties.deaths,
                                            todayDeaths: properties.todayDeaths,
                                            critical: properties.critical,
                                            casesPerOneMillion: properties.casesPerOneMillion,
                                            deathsPerOneMillion: properties.deathsPerOneMillion,
                                            centerLat: properties.centerLat,
                                            centerLng: properties.centerLng,
                                            totalTests: properties.totalTests,
                                            testsPerOneMillion: properties.testsPerOneMillion,
                                            predictions: [
                                                {
                                                    date: predictionsArray[0].date,
                                                    value: predictionsArray[0].value
                                                },
                                                {
                                                    date: predictionsArray[1].date,
                                                    value: predictionsArray[1].value
                                                }
                                            ]
                                        })

                                        this.onSelectedCountries(data)

                                    });
                                }
                            }
                        }}
                    </MapContext.Consumer>
                </Mapbox>
            </div>
        );
    }
}

export default inject("store")(observer(Map));
