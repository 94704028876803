import { types } from "mobx-state-tree"
import { Prediction } from "./Prediction"

export const Country = types
    .model({
        country: types.string,
        countryTr: types.string,
        cases: types.number,
        todayCases: types.number,
        deaths: types.number,
        todayDeaths: types.number,
        recovered: types.number,
        critical: types.number,
        casesPerOneMillion: types.number,
        deathsPerOneMillion: types.number,
        centerLat: types.number,
        centerLng: types.number,
        totalTests: types.number,
        testsPerOneMillion: types.number,
        predictions: types.array(Prediction)
    })
