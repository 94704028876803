import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { observer, inject } from "mobx-react"

import Dashboard from "./components/Dashboard"
class App extends Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/" component={Dashboard} />
        </Switch>
      </Router>
    )
  }
}

export default inject("store")(observer(App));

